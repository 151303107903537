import React, { Fragment, useEffect, useRef } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { Box, Center } from '@chakra-ui/react';
import { useStore } from '../../stores';
import GreenScreenVideo from './GreenScreenVideo';

export interface Props {
    avatar_config: RapportAvatarConfig;
    rapportSceneId: string;
    backgroundSrc?: string;
    shouldUseGreenScreenRemoval?: boolean;
}

const DEFAULT_BACKGROUND_COLOR = '#EEEEEE';
const RAPPORT_BASE_URL = 'https://api.rapport.cloud/api/v1/';

export default function AvatarWrapperRapport({
    backgroundSrc,
    avatar_config,
    rapportSceneId,
    shouldUseGreenScreenRemoval,
}: Props) {
    const { scene } = avatar_config;

    const isSourcesDrawerOpen = useStore(state => state.isSourcesDrawerOpen);

    const greenscreenVideoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (shouldUseGreenScreenRemoval) {
            const rapportScene = document.getElementById(rapportSceneId);
            rapportScene?.addEventListener('sessionConnected', () => {
                const rapportVideo = rapportScene?.shadowRoot?.querySelector(
                    'video',
                ) as HTMLVideoElement;
                const video = greenscreenVideoRef.current;
                if (rapportVideo && video) {
                    video.srcObject = rapportVideo.srcObject;
                    rapportVideo.pause();
                } else {
                    console.error('Rapport video element not found');
                }
            });
        }
    }, [shouldUseGreenScreenRemoval, rapportSceneId]);

    return (
        <Fragment>
            {backgroundSrc && !backgroundSrc.startsWith('#') ? (
                <Box
                    w='100%'
                    h='100%'
                    backgroundImage={backgroundSrc}
                    backgroundSize='cover'
                    backgroundPosition='bottom'
                />
            ) : (
                <Box
                    w='100%'
                    h='100%'
                    backgroundColor={backgroundSrc || DEFAULT_BACKGROUND_COLOR}
                />
            )}
            {isDesktop && (
                <Box
                    h='100%'
                    w={
                        avatar_config.fillScreen
                            ? '100%'
                            : { base: '100%', xl: '60%' }
                    }
                    position='fixed'
                    left={
                        avatar_config.fillScreen
                            ? '0px'
                            : {
                                  base: '-300px',
                                  xl: isSourcesDrawerOpen ? '0px' : '100px',
                              }
                    }
                    bottom='0px'
                >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <rapport-scene
                        id={rapportSceneId}
                        base-url={RAPPORT_BASE_URL}
                        project-id={scene.projectId}
                        project-token={scene.projectToken}
                        ai-user-id={scene.aiUserId}
                        lobby-zone-id={scene.lobbyZoneId}
                        style={
                            shouldUseGreenScreenRemoval
                                ? {
                                      display: 'none',
                                  }
                                : {
                                      width: '100%',
                                      height: '100%',
                                  }
                        }
                    />
                    {shouldUseGreenScreenRemoval && (
                        <GreenScreenVideo
                            playsInline
                            autoPlay
                            ref={greenscreenVideoRef}
                            style={{
                                height: '100%',
                            }}
                        />
                    )}
                </Box>
            )}
            {isMobile && (
                <Center
                    h={avatar_config.fillScreen ? '100%' : '90%'}
                    w='100%'
                    position='fixed'
                    bottom='0'
                >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <rapport-scene
                        id={rapportSceneId}
                        base-url={RAPPORT_BASE_URL}
                        project-id={scene.projectId}
                        project-token={scene.projectToken}
                        ai-user-id={scene.aiUserId}
                        lobby-zone-id={scene.lobbyZoneId}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Center>
            )}
        </Fragment>
    );
}
