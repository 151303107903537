import * as React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

const Hint: React.FC<ButtonProps> = ({ children, ...rest }) => {
    return (
        <Button
            minW='150px'
            maxW='150px'
            h='80px'
            px='10px'
            whiteSpace='normal'
            backgroundColor='dark.900'
            border='1px solid'
            borderColor='dark.500'
            color='white'
            fontSize='sm'
            fontWeight='normal'
            sx={{
                _focus: { boxShadow: 'none' },
                _active: { backgroundColor: 'none' },
            }}
            _hover={{ opacity: '75%' }}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default Hint;
