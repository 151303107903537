import React from 'react';
import useAsyncError from '../../hooks/useAsyncError';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { moocAPI } from '../../services';
import { UserData } from '../user/UserSettingsPageContent';
import { FormikValues } from 'formik';
import {
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SkeletonText,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import UserDetailsForm from '../user/UserDetailsForm';
import UserPasswordForm from '../user/UserPasswordForm';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';

const UserSettingsModal: React.FC<{ show: boolean; onClose: () => void }> = ({
    show,
    onClose,
}) => {
    const throwAsyncError = useAsyncError();
    const { data: userData, isSuccess } = useQuery(
        ['user-data'],
        () => moocAPI.get('student/user'),
        {
            onError: throwAsyncError,
        },
    );
    const queryClient = useQueryClient();

    const updateUserData = (userData: UserData): Promise<any> => {
        return moocAPI.patch('student/update', userData);
    };

    const onSubmit = (values: FormikValues): Promise<any> => {
        return updateUserData(values).then(res => {
            queryClient.setQueryData(['user-data'], res);
        });
    };

    return (
        <ModalWithNoInitialFocus
            isOpen={show}
            onClose={onClose}
            size='2xl'
            scrollBehavior='inside'
        >
            <ModalOverlay />
            {/*
            // The upgrade is causing an issue as part of the chakra types.
            // The suggested fix is to upgrade to V2, however that will be
            // done as follow up
            */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore  */}
            <ModalContent>
                <ModalHeader>Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs orientation='vertical'>
                        <TabList
                            borderInlineStart='none'
                            borderInlineEnd='2px solid var(--chakra-colors-gray-200)'
                        >
                            <Tab
                                pl={0}
                                justifyContent='start'
                                borderInlineStart='none'
                                marginInlineStart='none'
                                _selected={{
                                    borderInlineEnd:
                                        '2px solid var(--chakra-colors-brand-black-900)',
                                    marginInlineEnd: '-2px',
                                }}
                            >
                                Profile
                            </Tab>
                            <Tab
                                pl={0}
                                justifyContent='start'
                                borderInlineStart='none'
                                marginInlineStart='none'
                                _selected={{
                                    borderInlineStart: 'none',
                                    marginInlineStart: 'none',
                                    borderInlineEnd:
                                        '2px solid var(--chakra-colors-brand-black-900)',
                                    marginInlineEnd: '-2px',
                                }}
                            >
                                Password
                            </Tab>
                        </TabList>
                        <TabPanels p={5}>
                            <TabPanel>
                                <SkeletonText
                                    noOfLines={3}
                                    lineHeight='1.2rem'
                                    isLoaded={isSuccess}
                                >
                                    <UserDetailsForm
                                        userData={userData}
                                        onSubmit={onSubmit}
                                    />
                                </SkeletonText>
                            </TabPanel>
                            <TabPanel>
                                <UserPasswordForm onSubmit={onSubmit} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default UserSettingsModal;
