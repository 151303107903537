import * as React from 'react';
import { useState } from 'react';
import { Grid, GridItem, HStack } from '@chakra-ui/react';
import Chat, { Props as ChatProps } from '../../organisms/Chat/Chat';
import ControlToggle from '../../molecules/ControlToggle/ControlToggle';
import { useStoreWithArray } from '../../stores';

const AvatarDesktopOverlayTemplate = (props: ChatProps) => {
    const { isAudioOn, toggleAudio, isSourcesDrawerOpen } = useStoreWithArray([
        'isAudioOn',
        'toggleAudio',
        'isSourcesDrawerOpen',
    ]);

    const [showChatHistory, setShowChatHistory] = useState(true);

    return (
        <Grid
            h='100%'
            w='100%'
            templateColumns={{
                base: '50% 50%',
                xl: isSourcesDrawerOpen ? '1fr 1fr 20%' : '60% 40%',
            }}
            position='fixed'
            bottom='0px'
            zIndex={10}
            p='80px 0px 20px 0px'
        >
            <GridItem />
            <GridItem minH={0} display='flex' flexDirection='column'>
                <Chat {...props} showChatHistory={showChatHistory} />
                <HStack
                    w='80%'
                    justifyContent='flex-end'
                    m='10px auto 10px auto'
                >
                    <ControlToggle
                        ariaLabel='Audio toggle button'
                        icon={isAudioOn ? 'audioOn' : 'audioOff'}
                        buttonBackgroundColor={isAudioOn ? 'white' : 'black'}
                        tooltipLabel={
                            isAudioOn ? 'Turn audio off' : 'Turn audio on'
                        }
                        onClick={() => toggleAudio()}
                    />
                    <ControlToggle
                        ariaLabel='Chat history toggle button'
                        icon='chat'
                        buttonBackgroundColor={
                            showChatHistory ? 'white' : 'black'
                        }
                        tooltipLabel={
                            showChatHistory
                                ? 'Turn chat history off'
                                : 'Turn chat history on'
                        }
                        onClick={() => setShowChatHistory(value => !value)}
                    />
                </HStack>
            </GridItem>
        </Grid>
    );
};

export default AvatarDesktopOverlayTemplate;
