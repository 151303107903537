import React, { Fragment } from 'react';
import DashboardPageLayout from '../../components/layouts/DashboardPageLayout';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    chakra,
    Container,
    Heading,
    HStack,
    Skeleton,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ROUTES from '../../consts/routes';
import ActivityCard from './ActivityCard/ActivityCard';
import { useActivities, useCourse, useCourseJustCompleted } from './useCourse';
import PageHeader from './PageHeader';
import CourseCompleteModal from '../../components/modals/CourseCompleteModal';
import { isMobile } from 'react-device-detect';
import useStyling from '@recourseai/components/src/theme/useStyling';

export const CourseAchievements = ({
    course,
    isMobileBase,
}: {
    course: Course;
    isMobileBase?: boolean;
}) => {
    return (
        <VStack
            borderRadius='lg'
            bgColor={isMobileBase ? 'dark.480' : 'brand.black.200'}
            p={5}
            spacing={3}
            align='left'
            my={isMobileBase ? '50px' : '0px'}
        >
            <Heading as='h3' size='md'>
                Course achievements
            </Heading>
            {course.achievements.map(achievement => (
                <HStack key={achievement} spacing={3}>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        color='var(--chakra-colors-green-300)'
                        size='lg'
                    />
                    <chakra.span>{achievement}</chakra.span>
                </HStack>
            ))}
        </VStack>
    );
};

const CourseOverview: React.FC<{ course: Course }> = ({ course }) => {
    const { isBase } = useStyling();

    return (
        <Fragment>
            <Box dangerouslySetInnerHTML={{ __html: course.description }} />
            {course.achievements && !(isMobile && isBase) && (
                <CourseAchievements course={course} />
            )}
        </Fragment>
    );
};

interface BreadCrumb {
    title: string;
    url: string;
}

const CourseOverviewPageV2: React.FC<{ breadCrumbs: BreadCrumb[] }> = props => {
    const { courseId, activityId } = useParams<{
        courseId: string;
        activityId?: string;
    }>();
    const { course, enrollMutation, isLoading } = useCourse(courseId);
    const { activities } = useActivities(courseId);

    const breadCrumbs = [...props.breadCrumbs];
    if (course) {
        breadCrumbs.push({
            title: course.title,
            url: `/${ROUTES.COURSES}/${courseId}`,
        });
    }

    const courseActivities =
        course?.sections.map(section => section.activities).flat() || [];

    const history = useHistory();
    const activeActivityMode =
        (history.location.state as any)?.activeActivityMode || null;

    const {
        isOpen: showCourseCompleteModal,
        onClose: closeCourseCompleteModal,
        onOpen: openCourseCompleteModal,
    } = useDisclosure();
    useCourseJustCompleted(course, openCourseCompleteModal);

    if (!isLoading && course === null) {
        return <Redirect to={`/${ROUTES.PAGE_NOT_FOUND}`} />;
    }

    return (
        <DashboardPageLayout
            noPadding
            breadCrumbs={{ textColor: 'white', items: breadCrumbs }}
        >
            <PageHeader courseId={courseId} />
            <Container py={5} maxW={['5xl', null, null, null, null, '60%']}>
                {!!course && !!activities ? (
                    <Accordion
                        defaultIndex={course.student_course ? [1] : [0, 1]}
                        allowMultiple
                    >
                        <AccordionItem>
                            <AccordionButton>
                                <Heading
                                    flex={1}
                                    textAlign='left'
                                    fontSize='xl'
                                >
                                    Overview
                                </Heading>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel>
                                <CourseOverview course={course} />
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Heading
                                    flex={1}
                                    textAlign='left'
                                    fontSize='xl'
                                >
                                    Activities ({courseActivities.length})
                                </Heading>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel>
                                <VStack spacing={4} align='stretch'>
                                    {courseActivities.map((activity, i) => {
                                        const a = activities?.find(
                                            a => a.id === activity.id,
                                        );
                                        let isHighlighted = false;
                                        if (activityId) {
                                            isHighlighted =
                                                activityId ===
                                                activity.id.toString();
                                        }
                                        return (
                                            <ActivityCard
                                                key={activity.id}
                                                index={i + 1}
                                                course={course}
                                                // The activity coming from 'activities/' could have additional info
                                                // e.g. writing assignment data
                                                activity={a || activity}
                                                studentActivity={
                                                    a?.student_activity
                                                }
                                                isHighlighted={isHighlighted}
                                                isActive={
                                                    activeActivityMode ===
                                                    activity.id
                                                }
                                                onStartActivityMode={() => {
                                                    if (
                                                        !course?.student_course &&
                                                        !course
                                                            ?.product_information
                                                            .on_subscription_plan &&
                                                        course
                                                            ?.product_information
                                                            .one_off_purchase
                                                            ?.product !== null
                                                    ) {
                                                        enrollMutation.mutate(
                                                            course,
                                                        );
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </VStack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                ) : (
                    <VStack spacing={4} align='stretch'>
                        {[...Array(3).keys()].map(i => (
                            <Skeleton key={i} height='260px' />
                        ))}
                    </VStack>
                )}
            </Container>

            {course && (
                <CourseCompleteModal
                    course={course}
                    show={
                        showCourseCompleteModal && activeActivityMode === null
                    }
                    onClose={closeCourseCompleteModal}
                />
            )}
        </DashboardPageLayout>
    );
};

export default CourseOverviewPageV2;
