// Normally, any references of process.env.REACT_APP_[VARIABLE_NAME] are replaced
// at build time, however this means we need to create a separate image for each
// environment.
// The following is a workaround implementation which injects the config
// based on the config present in the environment inside the docker image
const config: { [key: string]: string | undefined } =
    (window as any)?.APP_CONFIG ?? {};

const appConfig = {
    REACT_APP_ENV: config.REACT_APP_ENV ?? process.env.REACT_APP_ENV,
    REACT_APP_BETA_FEATURES:
        config.REACT_APP_BETA_FEATURES ?? process.env.REACT_APP_BETA_FEATURES,
    REACT_APP_BUILD_YEAR:
        config.REACT_APP_BUILD_YEAR ?? process.env.REACT_APP_BUILD_YEAR,

    REACT_APP_BRANDING_LOGO:
        config.REACT_APP_BRANDING_LOGO ?? process.env.REACT_APP_BRANDING_LOGO,
    REACT_APP_BRANDING_TITLE:
        config.REACT_APP_BRANDING_TITLE ?? process.env.REACT_APP_BRANDING_TITLE,
    REACT_APP_BRANDING_ORGANISATION:
        config.REACT_APP_BRANDING_ORGANISATION ??
        process.env.REACT_APP_BRANDING_ORGANISATION,
    REACT_APP_BRANDING_HOME_URL:
        config.REACT_APP_BRANDING_HOME_URL ??
        process.env.REACT_APP_BRANDING_HOME_URL,
    REACT_APP_BRANDING_LABEL_TEXT:
        config.REACT_APP_BRANDING_LABEL_TEXT ??
        process.env.REACT_APP_BRANDING_LABEL_TEXT,
    REACT_APP_BRANDING_ONBOARDING_URL:
        config.REACT_APP_BRANDING_ONBOARDING_URL ??
        process.env.REACT_APP_BRANDING_ONBOARDING_URL,
    REACT_APP_BRANDING_SUPPORT_EMAIL:
        config.REACT_APP_BRANDING_SUPPORT_EMAIL ??
        process.env.REACT_APP_BRANDING_SUPPORT_EMAIL,
    REACT_APP_BRANDING_CUSTOM_FOOTER:
        config.REACT_APP_BRANDING_CUSTOM_FOOTER ??
        process.env.REACT_APP_BRANDING_CUSTOM_FOOTER,

    REACT_APP_RAPPORT_TIMEOUT_MINUTES:
        config.REACT_APP_RAPPORT_TIMEOUT_MINUTES ??
        process.env.REACT_APP_RAPPORT_TIMEOUT_MINUTES,
    REACT_APP_RAPPORT_SPEAK_TIMEOUT_SECONDS:
        config.REACT_APP_RAPPORT_SPEAK_TIMEOUT_SECONDS ??
        process.env.REACT_APP_RAPPORT_SPEAK_TIMEOUT_SECONDS,
    REACT_APP_END_CALL_HINT_TIMEOUT_MINS:
        config.REACT_APP_END_CALL_HINT_TIMEOUT_MINS ??
        process.env.REACT_APP_END_CALL_HINT_TIMEOUT_MINS,
    REACT_APP_TRIAL_LOCKED_COURSES_URL:
        config.REACT_APP_TRIAL_LOCKED_COURSES_URL ??
        process.env.REACT_APP_TRIAL_LOCKED_COURSES_URL,

    REACT_APP_AUTH_ENDPOINT:
        config.REACT_APP_AUTH_ENDPOINT ?? process.env.REACT_APP_AUTH_ENDPOINT,
    REACT_APP_AUTH_CLIENT_ID:
        config.REACT_APP_AUTH_CLIENT_ID ?? process.env.REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_AUTH_REALM:
        config.REACT_APP_AUTH_REALM ?? process.env.REACT_APP_AUTH_REALM,
    REACT_APP_HOURS_UNTIL_SESSION_EXPIRATION:
        config.REACT_APP_HOURS_UNTIL_SESSION_EXPIRATION ??
        process.env.REACT_APP_HOURS_UNTIL_SESSION_EXPIRATION,

    REACT_APP_MOOC_API_ROOT_URL:
        config.REACT_APP_MOOC_API_ROOT_URL ??
        process.env.REACT_APP_MOOC_API_ROOT_URL,
    REACT_APP_MOOC_API_ENDPOINT:
        config.REACT_APP_MOOC_API_ENDPOINT ??
        process.env.REACT_APP_MOOC_API_ENDPOINT,
    REACT_APP_INTERACTIONS_URL:
        config.REACT_APP_INTERACTIONS_URL ??
        process.env.REACT_APP_INTERACTIONS_URL,
    REACT_APP_UNITY_BUILD_URL:
        config.REACT_APP_UNITY_BUILD_URL ??
        process.env.REACT_APP_UNITY_BUILD_URL,
    REACT_APP_MOOC_WS_API_ROOT_URL:
        config.REACT_APP_MOOC_WS_API_ROOT_URL ??
        process.env.REACT_APP_MOOC_WS_API_ROOT_URL,
    REACT_APP_SPEECH_RECOGNITION_API_ROOT_URL:
        config.REACT_APP_SPEECH_RECOGNITION_API_ROOT_URL ??
        process.env.REACT_APP_SPEECH_RECOGNITION_API_ROOT_URL,

    REACT_APP_STRIPE_PUBLISHABLE_KEY:
        config.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    REACT_APP_SENTRY_DNS:
        config.REACT_APP_SENTRY_DNS ?? process.env.REACT_APP_SENTRY_DNS,
    REACT_APP_MIXPANEL_PROJECT_TOKEN:
        config.REACT_APP_MIXPANEL_PROJECT_TOKEN ??
        process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN,
    REACT_APP_FULLSTORY_ORG_ID:
        config.REACT_APP_FULLSTORY_ORG_ID ??
        process.env.REACT_APP_FULLSTORY_ORG_ID,
    REACT_APP_SENTRY_ORGANISATION_SLUG:
        config.REACT_APP_SENTRY_ORGANISATION_SLUG ??
        process.env.REACT_APP_SENTRY_ORGANISATION_SLUG,

    REACT_APP_SPEECH_RECOGNITION_API_SAMPLE_RATE: parseInt(
        config.REACT_APP_SPEECH_RECOGNITION_API_SAMPLE_RATE ??
            process.env.REACT_APP_SPEECH_RECOGNITION_API_SAMPLE_RATE ??
            '16000',
    ),
    REACT_APP_STOP_MICROPHONE_DELAY_MS: parseInt(
        config.REACT_APP_STOP_MICROPHONE_DELAY_MS ??
            process.env.REACT_APP_STOP_MICROPHONE_DELAY_MS ??
            '500',
    ),
    REACT_APP_SPEECH_RECOGNITION_EVENT_TIMEOUT_MS: parseInt(
        config.REACT_APP_SPEECH_RECOGNITION_EVENT_TIMEOUT_MS ??
            process.env.REACT_APP_SPEECH_RECOGNITION_EVENT_TIMEOUT_MS ??
            '5000',
    ),

    // This should be updated at build time only
    REACT_APP_SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
};

// Validate that each key has a valid value
const missingValues = Object.entries(appConfig)
    .filter(([_key, value]) => value === undefined)
    .map(([key]) => key);

if (missingValues.length > 0) {
    console.warn(`Missing config values for ${missingValues.join(', ')}.`);
}

export default appConfig;
