import * as React from 'react';
import {
    isDesktop,
    isMobile,
    isTablet,
    useMobileOrientation,
} from 'react-device-detect';
import AvatarMobileOverlayTemplate from './AvatarMobileOverlayTemplate';
import AvatarDesktopOverlayTemplate from './AvatarDesktopOverlayTemplate';
import { Props } from '../../organisms/Chat/Chat';
const AvatarUIOverlayTemplate = (props: Props) => {
    const { isLandscape } = useMobileOrientation();
    if (isDesktop || (isTablet && isLandscape)) {
        return <AvatarDesktopOverlayTemplate {...props} />;
    } else if (isMobile) {
        // TODO lock portrait mode
        return <AvatarMobileOverlayTemplate {...props} />;
    }
    return null;
};

export default AvatarUIOverlayTemplate;
