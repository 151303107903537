import {
    faChartBar,
    faUsers,
    faHome,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import ROUTES from '../../../consts/routes';
import { MoocFeature } from '../../contexts/StudentFeaturesContext';

const NavItems: {
    icon: IconDefinition;
    text: string;
    feature: MoocFeature;
    route: string;
}[] = [
    {
        icon: faHome,
        text: 'Home',
        feature: 'courses',
        route: `/${ROUTES.COURSES}`,
    },
    {
        icon: faUsers,
        text: 'Teams',
        route: `/${ROUTES.PORTFOLIO}`,
        feature: 'portfolio',
    },
    {
        icon: faChartBar,
        text: 'Insights',
        route: `/${ROUTES.INSIGHTS}`,
        feature: 'courses',
    },
];

export const NavItemsMobile: {
    icon: IconDefinition;
    text: string;
    feature: MoocFeature;
    route: string;
}[] = [
    {
        icon: faHome,
        text: 'Home',
        feature: 'courses',
        route: `/${ROUTES.COURSES}`,
    },
    {
        icon: faChartBar,
        text: 'Insights',
        route: `/${ROUTES.INSIGHTS}`,
        feature: 'courses',
    },
];

export default NavItems;
