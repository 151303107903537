import * as React from 'react';
import { Fragment } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface Props {
    captions: string;
}

export default function Captions({ captions }: Props) {
    return (
        <Fragment>
            <Box backgroundColor='dark.500' borderRadius='10px' p='10px'>
                <Text color='white' m='0px'>
                    {captions}
                </Text>
            </Box>
        </Fragment>
    );
}
