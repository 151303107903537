import React, { Fragment, useState } from 'react';
import {
    Box,
    Button,
    Center,
    chakra,
    Flex,
    Heading,
    IconButton,
    Image,
    LinkBox,
    LinkOverlay,
    Progress,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAward,
    faClock,
    faColumns,
    faLock,
    faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import ROUTES from '../../../consts/routes';
import { useCourseCardSize } from './utils';
import { isMobile } from 'react-device-detect';

export interface CourseCardProps {
    course?: Course;
    variant: 'in-progress' | 'un-enrolled' | 'completed' | 'locked';
    courseUrlBuilder?: (course: Course) => string;
    isInsideCarousel?: boolean;
}

const CourseCard: React.FC<CourseCardProps> = ({
    course,
    variant,
    courseUrlBuilder = (course: Course) => `/${ROUTES.COURSES}/${course.id}`,
    isInsideCarousel,
}) => {
    const cardSize = useCourseCardSize(variant, isInsideCarousel);
    const [isHovered, setIsHovered] = useState(false);

    return (
        <LinkBox
            // p={px(BOX_SHADOW_SPREAD)}
            {...cardSize}
            flex={`0 0 ${cardSize.width}`}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Flex
                direction='column'
                px={5}
                py={4}
                borderRadius='15px'
                h='100%'
                position='relative'
                bg='white'
            >
                {variant === 'locked' && (
                    <Fragment>
                        <Box
                            h='100%'
                            w='100%'
                            position='absolute'
                            top={0}
                            left={0}
                            bgColor='white'
                            opacity='0.4'
                            borderRadius='15px'
                            zIndex='15'
                        />
                        <Box
                            position='absolute'
                            top='25px'
                            right='20px'
                            zIndex={10}
                        >
                            <FontAwesomeIcon size='lg' icon={faLock} />
                        </Box>
                    </Fragment>
                )}
                {course && (
                    <Fragment>
                        <Box mb={3}>
                            <Heading
                                fontSize='1.1rem'
                                as='h6'
                                mb={1}
                                noOfLines={1}
                                fontWeight='bold'
                                fontFamily='Roboto'
                            >
                                {variant === 'locked' ? (
                                    course.title
                                ) : (
                                    <LinkOverlay
                                        as={RouterLink}
                                        to={courseUrlBuilder(course)}
                                    >
                                        {course.title}
                                    </LinkOverlay>
                                )}
                            </Heading>
                            <chakra.span noOfLines={1} color='brand.black.400'>
                                By {course.provider.name}
                            </chakra.span>
                        </Box>
                        <Box position='relative'>
                            <Image
                                src={course.image_url}
                                alt={`${course.title} course image`}
                                width='100%'
                                sx={{
                                    aspectRatio: '3 / 2',
                                }}
                                loading='lazy'
                            />
                            {(((isHovered || isMobile) &&
                                variant !== 'locked') ||
                                variant === 'completed') && (
                                <Center
                                    position='absolute'
                                    bottom={0}
                                    w='100%'
                                    bgGradient='linear(to-t, blackAlpha.900, blackAlpha.700, blackAlpha.300, blackAlpha.50, transparent)'
                                    pt='30%'
                                    pb={2}
                                    px={3}
                                >
                                    {variant === 'in-progress' && (
                                        <RouterLink
                                            style={{ width: '100%' }}
                                            to={`/${ROUTES.COURSES}/${
                                                course.id
                                            }${
                                                course.student_course
                                                    ?.resume_activity
                                                    ? `/${ROUTES.ACTIVITY}/${course.student_course.resume_activity.id}`
                                                    : ''
                                            }`}
                                        >
                                            <Center>
                                                <chakra.span
                                                    fontWeight='bold'
                                                    color='white'
                                                >
                                                    {
                                                        course.student_course
                                                            ?.resume_activity
                                                            ?.title
                                                    }
                                                </chakra.span>
                                                <Spacer />
                                                <IconButton
                                                    borderRadius='100%'
                                                    bgColor='white'
                                                    aria-label='Resume activity'
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faPlay}
                                                            size='sm'
                                                        />
                                                    }
                                                />
                                            </Center>
                                        </RouterLink>
                                    )}
                                    {variant === 'un-enrolled' && (
                                        <Fragment>
                                            <Box color='white' fontSize='sm'>
                                                <FontAwesomeIcon
                                                    icon={faColumns}
                                                />{' '}
                                                {
                                                    course.number_of_required_activities
                                                }{' '}
                                                activities <br />
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                />{' '}
                                                {course.estimated_time_minutes}{' '}
                                                minutes <br />
                                            </Box>
                                            <Spacer />
                                            <RouterLink
                                                to={`/${ROUTES.COURSES}/${course.id}`}
                                            >
                                                <Button
                                                    size='sm'
                                                    bgColor='white'
                                                    colorScheme='gray'
                                                >
                                                    Explore
                                                </Button>
                                            </RouterLink>
                                        </Fragment>
                                    )}
                                    {variant === 'completed' && (
                                        <Fragment>
                                            <Box color='white' fontSize='2xl'>
                                                <FontAwesomeIcon
                                                    icon={faAward}
                                                />{' '}
                                                {course.student_course?.score}%
                                            </Box>
                                            <Spacer />
                                        </Fragment>
                                    )}
                                </Center>
                            )}
                        </Box>
                        <Flex
                            direction='column'
                            flexGrow={1}
                            mt={4}
                            overflow='hidden'
                        >
                            {variant === 'in-progress' && (
                                <Progress
                                    size='sm'
                                    borderRadius='5px'
                                    colorScheme='brand.black'
                                    value={
                                        course.student_course
                                            ?.course_progress_percentage
                                    }
                                />
                            )}

                            {variant === 'un-enrolled' && (
                                <Text mb={0} noOfLines={2}>
                                    {course.sub_title}
                                </Text>
                            )}
                        </Flex>
                    </Fragment>
                )}
            </Flex>
        </LinkBox>
    );
};

export default CourseCard;
