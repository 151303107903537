import * as React from 'react';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import AvatarWrapperRapport, {
    Props as RapportAvatarProps,
} from '../../organisms/AvatarWrapper/AvatarWrapperRapport';
import AvatarUIOverlayTemplate from '../AvatarUIOverlayTemplate/AvatarUIOverlayTemplate';
import { Props as ChatProps } from '../../organisms/Chat/Chat';
import { Center } from '@chakra-ui/react';
import LoadingSpinner from '../../../../../apps/mooc-frontend/src/components/generic/LoadingSpinner';
import { useStoreWithArray } from '../../stores';
import {
    AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE,
    AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
    AVATAR_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
    AvatarModalName,
    AvatarSessionCouldNotInitialiseModal,
    AvatarSessionDisconnectedTimeoutModal,
    AvatarSessionDisconnectedTtsFailedModal,
    AvatarSessionDisconnectedAsrFailedModal,
    AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/RapportModalTemplates';
import AvatarWrapperSimli, {
    AvatarWrapperSimliRef,
    Props as SimliAvatarProps,
} from '../../organisms/AvatarWrapper/AvatarWrapperSimli';
import useRapportOrchestrator from './useRapportOrchestrator';
import {
    SpeechRecognitionProps,
    useSpeechRecognition,
} from '../../hooks/speech/useSpeechRecognition';
import { SimliClient } from 'simli-client';
import { isMobile } from 'react-device-detect';

const RAPPORT_SCENE_ID = 'rapportScene';
interface RapportAvatarTemplateProps
    extends ChatProps,
        Omit<RapportAvatarProps, 'rapportSceneId'>,
        Omit<SpeechRecognitionProps, 'setModal'> {}

export function RapportAvatarTemplate(props: RapportAvatarTemplateProps) {
    const [modal, setModal] = useState<AvatarModalName | null>();
    const { isRapportAsr, retryAsr } = useSpeechRecognition({
        sessionId: props.sessionId,
        transcriber_config: props.transcriber_config,
        processor_configs: props.processor_configs,
        setModal,
    });

    const { status, initRapport } = useRapportOrchestrator(
        RAPPORT_SCENE_ID,
        props.avatar_config,
        setModal,
        isRapportAsr,
    );

    const { setIsTextMode } = useStoreWithArray(['setIsTextMode']);
    const isLoading = status === 'connecting';

    return (
        <Fragment>
            <AvatarWrapperRapport
                rapportSceneId={RAPPORT_SCENE_ID}
                {...props}
            />

            {isLoading && (
                <Center position='fixed' w='100%' h='100%' top='0'>
                    <LoadingSpinner />
                    <span className='ml-2'> Loading </span>
                </Center>
            )}
            {!isLoading && <AvatarUIOverlayTemplate {...props} />}

            <AvatarSessionCouldNotInitialiseModal
                show={
                    modal ===
                    AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
            <AvatarSessionDisconnectedTtsFailedModal
                show={
                    modal ===
                    AVATAR_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
            <AvatarSessionDisconnectedAsrFailedModal
                show={
                    modal ===
                    AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                retryAsr={async (onRetryComplete?: () => void) => {
                    const onSuccess = () => setModal(null);
                    await retryAsr(onSuccess, onRetryComplete);
                }}
            />
            <AvatarSessionDisconnectedTimeoutModal
                show={
                    modal ===
                    AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                finish={() => {
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
        </Fragment>
    );
}

interface SimliAvatarTemplateProps
    extends ChatProps,
        Omit<SimliAvatarProps, 'simliConfig'>,
        Omit<SpeechRecognitionProps, 'setModal'> {
    avatar_config: SimliAvatarConfig;
}

type SimliStatus = 'idle' | 'connected' | 'disconnected' | 'failed';
export function SimliAvatarTemplate(props: SimliAvatarTemplateProps) {
    const [modal, setModal] = useState<AvatarModalName | null>(null);
    const { setIsTextMode } = useStoreWithArray(['setIsTextMode']);
    const simliAvatarWrapperRef = useRef<AvatarWrapperSimliRef | null>(null);

    const { retryAsr } = useSpeechRecognition({
        sessionId: props.sessionId,
        transcriber_config: props.transcriber_config,
        processor_configs: props.processor_configs,
        setModal,
    });

    const eventHandler = useCallback(
        (event: SimliStatus, simliClient: SimliClient) => {
            console.log('[SIMLI]: event ' + event);
            switch (event) {
                case 'connected':
                    setModal(null);
                    break;
                case 'failed':
                    // TODO reliably distinguish between failed to connect and disconnect because of timeout
                    // the simli client emits 'failed' and sometimes 'disconnected' after when it's a timeout
                    setModal(null); // This refreshes the button states if a new failure happens while trying to load again from the modal
                    setModal(
                        AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE.code,
                    );
                    break;
                case 'disconnected':
                    setModal(
                        AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE.code,
                    );
                    break;
                default:
                    break;
            }
        },
        [],
    );

    const simliConfig = useMemo(
        () => ({
            apiKey: props.avatar_config.apiKey || '',
            faceID:
                (!props.shouldUseGreenScreenRemoval &&
                    props.avatar_config.faceId_background) ||
                props.avatar_config.faceId ||
                '',
            handleSilence: true,
            maxSessionLength: 3600, // in seconds
            maxIdleTime: 3600, // in seconds
        }),
        [
            props.avatar_config.apiKey,
            props.avatar_config.faceId,
            props.avatar_config.faceId_background,
            props.shouldUseGreenScreenRemoval,
        ],
    );

    return (
        <Fragment>
            <AvatarWrapperSimli
                ref={simliAvatarWrapperRef}
                simliConfig={simliConfig}
                eventHandler={eventHandler}
                backgroundSrc={
                    (!props.shouldUseGreenScreenRemoval &&
                        props.avatar_config.background_blend) ||
                    props.backgroundSrc
                }
                shouldUseGreenScreenRemoval={props.shouldUseGreenScreenRemoval}
            />
            <AvatarUIOverlayTemplate {...props} />
            <AvatarSessionDisconnectedTimeoutModal
                show={
                    modal ===
                    AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                finish={() => {
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    simliAvatarWrapperRef.current?.start();
                }}
            />
            <AvatarSessionCouldNotInitialiseModal
                show={
                    modal ===
                    AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    simliAvatarWrapperRef.current?.start();
                }}
            />
            <AvatarSessionDisconnectedAsrFailedModal
                show={
                    modal ===
                    AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                retryAsr={async (onRetryComplete?: () => void) => {
                    const onSuccess = () => setModal(null);
                    await retryAsr(onSuccess, onRetryComplete);
                }}
            />
        </Fragment>
    );
}

export type Props = SimliAvatarTemplateProps | RapportAvatarTemplateProps;
export default function AvatarTemplate(_props: Props) {
    const query = new URLSearchParams(window.location.search);
    const props = { ..._props };

    if (query.has('greenscreen')) {
        props.shouldUseGreenScreenRemoval =
            query.get('greenscreen')!.toLocaleLowerCase() === 'true';
    } else if (props.avatar_config.type === 'simli' && !isMobile) {
        // TODO we need a backend config for rapport
        props.shouldUseGreenScreenRemoval = true;
    }

    if (query.has('apiKey') && query.has('faceID')) {
        props.avatar_config = {
            type: 'simli',
            faceId: query.get('faceID') || '',
            apiKey: query.get('apiKey') || '',
        };
    }

    if (props.avatar_config.type === 'simli') {
        // TODO - enforce this in the interactions api
        // Simli doesn't support rapport asr, so we need to change it to cloud
        if (props.transcriber_config.api === 'rapport') {
            props.transcriber_config.api = 'cloud';
        }

        return <SimliAvatarTemplate {...(props as SimliAvatarTemplateProps)} />;
    } else {
        return (
            <RapportAvatarTemplate {...(props as RapportAvatarTemplateProps)} />
        );
    }
}
