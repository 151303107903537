export const getCoursePageStyles = (
    isMobileBase: boolean,
    imageContainerHeight?: string,
    course?: Course,
) => {
    const isOverview = !!course?.overview_image_url;

    const coursePageStyles = {
        mobileBase: {
            breadCrumb: {
                sx: {
                    '& > ol': {
                        paddingInlineStart: '0px',
                        ml: '14px',
                    },
                },
            },
            imageContainer: {
                bgSize: isOverview
                    ? '100% 200px, 100% 200px, auto 440px'
                    : 'cover',
                bgPosition: `center top, center ${
                    isOverview ? '250px' : '65px'
                }, 92% top`,
                backgroundColor: 'black',
            },
            layoutContainer: {
                px: '25px',
                alignItems: 'flex-start',
            },
            heading: {
                mt: isOverview ? '275px' : '25px',
            },
        },
        otherSizes: {
            breadCrumb: {
                sx: {
                    '& > ol': {
                        paddingInlineStart: '40px',
                        ml: '0x',
                    },
                },
            },
            imageContainer: {
                h: imageContainerHeight,
                bgSize: 'cover',
                bgPosition: 'right top',
            },
            layoutContainer: {
                px: 20,
                alignItems: 'center',
            },
            heading: {
                mt: '0px',
            },
        },
    };

    return isMobileBase
        ? coursePageStyles.mobileBase
        : coursePageStyles.otherSizes;
};
