export interface MicrophoneConfig {
    sampleRate: number;
    bufferSize: number;
    onData: (data: Int16Array) => void;
    onError: (error: Error) => void;
}

export class MicrophoneManager {
    private onData: MicrophoneConfig['onData'];
    private _onError: MicrophoneConfig['onError'];
    private sampleRate: MicrophoneConfig['sampleRate'];
    private bufferSize: MicrophoneConfig['bufferSize'];

    private shouldBuffer = false;
    private shouldRecord = false;
    private audioBuffer: Int16Array[] = [];

    private microphoneDevice?: MediaDeviceInfo;
    private audioContext?: AudioContext;
    private audioStream?: MediaStream;
    private audioStreamSource?: MediaStreamAudioSourceNode;
    private audioProcessor?: ScriptProcessorNode;

    constructor(config: MicrophoneConfig) {
        this.onData = config.onData;
        this._onError = config.onError;

        this.sampleRate = config.sampleRate;
        this.bufferSize = config.bufferSize;

        navigator.mediaDevices.addEventListener(
            'devicechange',
            this.onDeviceChanged,
        );
    }

    isInitialized() {
        return !!this.audioStream;
    }

    private async initializeStream() {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                noiseSuppression: true,
                echoCancellation: true,
                sampleRate: this.sampleRate,
            },
        });

        if (!this.audioContext) {
            this.audioContext = new AudioContext({
                sampleRate: this.sampleRate,
            });
        }

        // Clean up old stream if it exists
        if (this.audioStream) {
            this.audioStream.getTracks().forEach(track => track.stop());
            this.audioStreamSource?.disconnect();
        }

        this.audioStream = stream;
        this.audioStreamSource = this.audioContext.createMediaStreamSource(
            stream,
        );

        // Create processor only if it doesn't exist
        if (!this.audioProcessor) {
            this.audioProcessor = this.audioContext.createScriptProcessor(
                this.bufferSize,
                1,
                1,
            );
            this.audioProcessor.onaudioprocess = this.onAudioProcess;
            this.audioProcessor.connect(this.audioContext.destination);
        }

        this.audioStreamSource.connect(this.audioProcessor);
        return true;
    }

    private onAudioProcess = (event: AudioProcessingEvent) => {
        if (!this.shouldRecord) return;

        const floatSamples = event.inputBuffer.getChannelData(0);
        const int16Samples = Int16Array.from(floatSamples.map(n => n * 32767));

        if (this.shouldBuffer) {
            this.audioBuffer.push(int16Samples);
            return;
        }

        this.onData(int16Samples);
    };

    private _initialize = async (skipInitialized = true) => {
        if (skipInitialized && this.isInitialized()) return true;

        if (!this.microphoneDevice) {
            this.microphoneDevice = await this.findMicrophoneDevice();
            console.info(
                '[MicrophoneManager]: found default microphone device',
                this.microphoneDevice,
            );
        }

        console.log('[MicrophoneManager]: initializing');
        return this.initializeStream();
    };

    initialize = async (skipInitialized = true) => {
        const startTime = performance.now();

        try {
            await this._initialize(skipInitialized);
            return true;
        } catch (error) {
            this.onError(error as Error);
        } finally {
            console.log(
                `[MicrophoneManager]: initialize took ${performance.now() -
                    startTime}ms`,
            );
        }
    };

    private findMicrophoneDevice = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const microphones = devices.filter(
            device => device.kind === 'audioinput',
        );

        return (
            devices.find(device => device.deviceId === 'default') ||
            (microphones.length ? microphones[0] : undefined)
        );
    };

    private onDeviceChanged = async (ev: Event) => {
        console.log('[MicrophoneManager]: Received device change event', ev);

        const newMicrophoneDevice = await this.findMicrophoneDevice();
        // The deviceId for the default device is always 'default'
        // So we use the label and groupId to check if the device has changed
        const isDeviceChanged =
            newMicrophoneDevice?.label !== this.microphoneDevice?.label ||
            newMicrophoneDevice?.groupId !== this.microphoneDevice?.groupId ||
            newMicrophoneDevice?.deviceId !== this.microphoneDevice?.deviceId;

        if (isDeviceChanged) {
            console.info(
                '[MicrophoneManager]: The default microphone has been changed - reinitializing',
                newMicrophoneDevice,
            );

            this.microphoneDevice = newMicrophoneDevice;
            await this.initialize(false);
        }
    };

    private onError = (error: Error) => {
        console.error('[MicrophoneManager]: on error', error);
        this.destroy();
        this._onError(error);
    };

    private _destroy = () => {
        this.audioContext?.close();
        this.audioContext = undefined;

        this.audioStream?.getTracks().forEach(track => track.stop());
        this.audioStream = undefined;

        this.audioStreamSource?.disconnect();
        this.audioStreamSource = undefined;

        this.audioProcessor?.disconnect();
        this.audioProcessor = undefined;

        navigator.mediaDevices.removeEventListener(
            'devicechange',
            this.onDeviceChanged,
        );
    };

    destroy = () => {
        const startTime = performance.now();

        try {
            this._destroy();
        } catch (error) {
            this.onError(error as Error);
        } finally {
            console.log(
                `[MicrophoneManager]: destroy took ${performance.now() -
                    startTime}ms`,
            );
        }
    };

    sendBufferedAudio = () => {
        if (!this.shouldBuffer) return;

        console.log('[MicrophoneManager]: sending buffered audio', {
            audioBuffer: this.audioBuffer.length,
        });

        this.audioBuffer.forEach(this.onData);

        this.audioBuffer = [];
        this.shouldBuffer = false;
    };

    setShouldRecord = (shouldRecord: boolean) => {
        this.shouldRecord = shouldRecord;

        this.audioBuffer = [];
        this.shouldBuffer = this.shouldRecord;
    };
}
