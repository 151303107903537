import * as React from 'react';
import { ModalProps, Modal as ChakraModal } from '@chakra-ui/react';
import { isMobile, isTablet } from 'react-device-detect';

// By default, chakra focuses on the first tabbable element when you open an overlay, e.g. Modal or Drawer
// it is common for that first tabbable elem to be the close button, which we don't want to focus on
// so, we use a HOC to change the default initial focus to one of the overlay's children

// This solution is a generalisation from
// https://github.com/chakra-ui/chakra-ui/issues/546#issuecomment-1273981084

interface CustomModalProps extends ModalProps {
    fullScreenOnMobile?: string;
}

const withInitialFocusOnChild = <
    PropT extends Pick<
        CustomModalProps,
        'initialFocusRef' | 'children' | 'size' | 'fullScreenOnMobile'
    >
>(
    WrappedComponent: React.FC<PropT>,
    indexOfFocusedChild: number,
    createChildProps = (ref: React.RefObject<HTMLElement>): any => ({ ref }),
) => {
    const OverlayWithNoFocus = React.forwardRef((props: PropT, ref) => {
        const initialFocusRef = React.useRef(null);

        let size = props.size;
        if (size?.startsWith('mobile-')) {
            size = size.replace('mobile-', '');
        } else {
            size = isMobile && !isTablet ? 'full' : size;
        }

        return (
            <WrappedComponent
                {...props}
                size={size}
                ref={ref}
                initialFocusRef={initialFocusRef}
            >
                {React.Children.map(props.children, (child, index) => {
                    if (index === indexOfFocusedChild && initialFocusRef) {
                        return React.cloneElement(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            child,
                            createChildProps(initialFocusRef),
                        );
                    }
                    return child;
                })}
            </WrappedComponent>
        );
    });

    OverlayWithNoFocus.displayName = WrappedComponent.displayName;
    return OverlayWithNoFocus;
};

// Assuming that the second child of an overlay is the content element (e.g. DrawerContent, ModalContent) we use it as focus target
//
// The assumption is based on docs where examples show
// <Modal>
//     <ModalOverlay/>
//     <ModalContent>
//        ...
//     </ModalContent>
// </Modal>
//
// https://chakra-ui.com/docs/components/modal#focus-on-specific-element

export const ModalWithNoInitialFocus = withInitialFocusOnChild(ChakraModal, 1);
