import React from 'react';
import {
    faArrowUp,
    faUser,
    faKeyboard,
    faTimes,
    faVolumeMute,
    faVolumeUp,
    faMicrophone,
    faMicrophoneSlash,
    faStop,
    faClosedCaptioning,
    faComment,
    faThumbsUp,
    faThumbsDown,
    faBars,
} from '@fortawesome/free-solid-svg-icons';

import {
    faThumbsUp as farThumbsUp,
    faThumbsDown as farThumbsDown,
} from '@fortawesome/free-regular-svg-icons';

import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const iconMap = {
    arrowUp: faArrowUp,
    user: faUser,
    keyboard: faKeyboard,
    x: faTimes,
    audioOn: faVolumeUp,
    audioOff: faVolumeMute,
    micOn: faMicrophone,
    micOff: faMicrophoneSlash,
    stop: faStop,
    captions: faClosedCaptioning,
    chat: faComment,
    thumbsUp: faThumbsUp,
    thumbsDown: faThumbsDown,
    thumbsUpOutline: farThumbsUp,
    thumbsDownOutline: farThumbsDown,
    hamburger: faBars,
};

export interface IconProps {
    iconChoice: keyof typeof iconMap;
    fontAwesomeProps?: Omit<FontAwesomeIconProps, 'icon'>;
}

const Icon: React.FC<IconProps> = ({ iconChoice, fontAwesomeProps }) => {
    const selectedIcon = iconMap[iconChoice];

    return <FontAwesomeIcon icon={selectedIcon} {...fontAwesomeProps} />;
};

export default Icon;
