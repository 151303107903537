// const BOX_SHADOW = '2px 2px 5px 2px var(--chakra-colors-brand-black-200)';
import useStyling from '@recourseai/components/src/theme/useStyling';
import { CourseCardProps } from './index';
import { isMobile } from 'react-device-detect';

const BOX_SHADOW_SPREAD = 0;

const px = (val: number) => `${val}px`;

export const useCourseCardSize = (
    variant: CourseCardProps['variant'],
    isInsideCarousel = false,
) => {
    const { isBase } = useStyling();

    const sizes: any = {
        width: 300,
    };

    switch (variant) {
        case 'locked':
            sizes['height'] = 300;
            break;
        case 'completed':
            sizes['height'] = 300;
            break;
        case 'in-progress':
            sizes['height'] = 330;
            break;
        case 'un-enrolled':
            sizes['height'] = 365;
            break;
    }

    Object.keys(sizes).forEach(key => {
        // if card is not inside carousel and (isMobile and isBase) then it needs to be 100% width to properly fit on different mobile
        // screens. If it's inside the carousel, then width is 300px to make sure it's not cut off on mobile
        sizes[key] =
            key === 'width' && isBase && isMobile && !isInsideCarousel
                ? '100%'
                : px(sizes[key] + 2 * BOX_SHADOW_SPREAD);
    });

    return sizes;
};
