import * as React from 'react';
import {
    Button,
    Heading,
    HStack,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { ModalWithNoInitialFocus } from '../../atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';
import withFullScreenSupport from '../../hoc/FullScreenModal';
import {
    InteractionContextAPI,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { useStoreWithArray } from '../../stores';

interface Props {
    show: boolean;
    onClose: () => void;
    onResumeLater: () => void;
    onComplete: () => void;
    courseId: string | undefined;
}

const ModalWithFullscreenSupport = withFullScreenSupport(
    ModalWithNoInitialFocus,
);

const ConfirmFinishModal: React.FC<Props> = ({
    show,
    onClose,
    onResumeLater,
    onComplete,
    courseId,
}) => {
    const { completeStage } = useInteractionContext(InteractionContextAPI);
    const { isConnected } = useInteractionContext(InteractionContextStatus);
    const { setErrorMessage, setCurrentModal } = useStoreWithArray([
        'setErrorMessage',
        'setCurrentModal',
    ]);

    const handleCompleteStage = async () => {
        onClose();
        const result = await completeStage();

        if (result.status === 'completed') {
            onComplete();
        } else if (result.error) {
            setErrorMessage(result.error);
            setCurrentModal('error');
        }
    };

    return (
        <ModalWithFullscreenSupport
            isOpen={show}
            onClose={onClose}
            isCentered
            size='mobile-md'
        >
            <ModalOverlay />
            <ModalContent
                backgroundColor='dark.900'
                border='1px solid'
                borderColor='dark.500'
                color='white'
            >
                <ModalHeader>
                    <Heading>Have you finished?</Heading>
                    <ModalCloseButton
                        color='dark.400'
                        _hover={{
                            backgroundColor: 'dark.480',
                            border: '1px solid',
                            borderColor: 'dark.430',
                        }}
                    />
                </ModalHeader>
                <ModalBody></ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button
                            backgroundColor='transparent'
                            color='white'
                            border='1px solid'
                            borderColor='dark.500'
                            _hover={{ backgroundColor: 'dark.600' }}
                            onClick={onResumeLater}
                        >
                            Resume later
                        </Button>
                        {isConnected && courseId && (
                            <Button
                                backgroundColor='white'
                                color='black'
                                _hover={{ backgroundColor: 'light.300' }}
                                type='submit'
                                onClick={handleCompleteStage}
                            >
                                Complete
                            </Button>
                        )}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </ModalWithFullscreenSupport>
    );
};

export default ConfirmFinishModal;
