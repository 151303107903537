import { useMemo } from 'react';
import { MicrophoneManager, MicrophoneConfig } from './microphone.utils';

export type UseMicrophoneConfig = Partial<
    Pick<MicrophoneConfig, 'bufferSize'>
> &
    Omit<MicrophoneConfig, 'bufferSize'>;

export function useMicrophone({
    onData,
    onError,
    sampleRate = 16000,
    bufferSize = 256,
}: UseMicrophoneConfig) {
    const microphoneManager = useMemo(
        () =>
            new MicrophoneManager({ onData, onError, sampleRate, bufferSize }),
        [onData, onError, sampleRate, bufferSize],
    );

    const {
        initialize,
        destroy,
        setShouldRecord,
        sendBufferedAudio,
    } = microphoneManager;

    return {
        initialize,
        destroy,
        setShouldRecord,
        sendBufferedAudio,
    };
}
