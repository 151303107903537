import React from 'react';
import {
    AspectRatio,
    Box,
    Button,
    Circle,
    Flex,
    Heading,
    HStack,
    Image,
    Skeleton,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { HEADER_HEIGHT } from '../../components/layouts/DashboardPageLayout';
import { useCourse } from './useCourse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import getHumanReadableFromDate from '../../utils/dateUtils';
import { getCoursePageStyles } from './utils';
import { CourseAchievements } from '.';
import { isMobile } from 'react-device-detect';
import useStyling from '@recourseai/components/src/theme/useStyling';

export const CompletedBox: React.FC<{ course: Course }> = ({ course }) => {
    return (
        <Flex>
            <Box flexGrow={1}>
                <Text fontWeight='bold'>
                    You have successfully finished the course
                </Text>
                <HStack>
                    <Circle size='2em' bgColor='green.400' textColor='white'>
                        <FontAwesomeIcon icon={faCheck} />
                    </Circle>
                    <Text>
                        <strong>Completed:</strong>{' '}
                        {getHumanReadableFromDate(
                            course.student_course?.completed_datetime,
                        )}
                    </Text>
                </HStack>
            </Box>
            {course.student_course?.score && (
                <Box borderLeft='1px solid black' pl={5}>
                    <Text fontWeight='bold' mb={0}>
                        Your grade
                    </Text>
                    <Text fontSize='4xl' fontFamily='telegraf' mb={0}>
                        {course.student_course?.score}%
                    </Text>
                </Box>
            )}
        </Flex>
    );
};

const getFadeGradients = (isMobile: boolean, isBase: boolean) => ({
    fadeGradientTop:
        isMobile && isBase
            ? 'linear-gradient(180deg, var(--chakra-colors-blackAlpha-700), rgba(0, 0, 0, 0))'
            : 'linear-gradient(135deg, var(--chakra-colors-blackAlpha-700), var(--chakra-colors-blackAlpha-50))',
    fadeGradientBottom: 'linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0))',
});

const PageHeader: React.FC<{ courseId: string }> = ({ courseId }) => {
    const { isBase } = useStyling();

    const { course, isLoading, enrollMutation } = useCourse(courseId, {
        enabled: false,
    });

    const isEnrolled = !!course?.student_course;
    const isCompleted = !!course?.student_course?.completed_datetime;

    const height = isCompleted ? '475px' : '385px';

    const coursePageStyles = getCoursePageStyles(
        isMobile && isBase,
        height,
        course,
    );

    const getBgImage = () => {
        const { fadeGradientTop, fadeGradientBottom } = getFadeGradients(
            isMobile,
            isBase,
        );

        if (course?.overview_image_url) {
            const gradients = [fadeGradientTop];
            if (isBase && isMobile) {
                gradients.push(fadeGradientBottom);
            }
            return `${gradients.join(', ')}, url(${course.overview_image_url})`;
        }

        return undefined;
    };

    if (isLoading || !course) {
        return (
            <Skeleton
                speed={0}
                isLoaded={!isLoading && !!course}
                height='350px'
            />
        );
    }

    return (
        <Box
            bg={!course.overview_image_url ? 'brand.gradient.full' : undefined}
            bgImage={getBgImage()}
            bgRepeat='no-repeat'
            textColor='white'
            w='100%'
            {...coursePageStyles.imageContainer}
        >
            <Box h={HEADER_HEIGHT} />
            <Flex
                {...coursePageStyles.layoutContainer}
                py={5}
                h='75%'
                flexDirection={isMobile && isBase ? 'column-reverse' : 'row'}
            >
                <Box
                    flexBasis={['100%', null, null, '70%', '55%', '50%']}
                    maxWidth='40em'
                >
                    <Heading as='h1' size='xl' {...coursePageStyles.heading}>
                        {course.title}
                    </Heading>
                    <Text>by {course.provider.name}</Text>
                    <Text>{course.sub_title}</Text>
                    {course.achievements && isMobile && isBase && (
                        <CourseAchievements
                            course={course}
                            isMobileBase={isMobile && isBase}
                        />
                    )}
                    {!isEnrolled && (
                        <Button
                            textColor='black'
                            bgColor='white'
                            px={10}
                            onClick={() => enrollMutation.mutate(course)}
                            isLoading={enrollMutation.isLoading}
                        >
                            {!course?.product_information
                                .on_subscription_plan &&
                            course?.product_information.one_off_purchase
                                ?.product !== null
                                ? 'Purchase'
                                : 'Enroll'}
                        </Button>
                    )}
                    {isCompleted && (
                        <Box
                            mt={10}
                            w='100%'
                            borderRadius='lg'
                            bgColor='whiteAlpha.800'
                            textColor='black'
                            p={7}
                        >
                            <CompletedBox course={course} />
                        </Box>
                    )}
                </Box>
                <Spacer minW='1em' />
                {!course.overview_image_url && (
                    <Box flex='0 0 auto'>
                        {!course.overview_image_url && (
                            <AspectRatio ratio={3 / 2} width='300px'>
                                <Image
                                    src={course.image_url}
                                    alt={`${course.title} image`}
                                    borderRadius='lg'
                                    objectFit='cover'
                                />
                            </AspectRatio>
                        )}
                    </Box>
                )}
            </Flex>
        </Box>
    );
};

export default PageHeader;
