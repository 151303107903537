import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import {
    ModalProps as ChakraModalProps,
    useDisclosure,
} from '@chakra-ui/react';
import fscreen from 'fscreen';

interface CustomModalProps extends ChakraModalProps {
    fullScreenOnMobile?: string;
}

const withFullScreenSupport = <ModalProps extends CustomModalProps>(
    WrappedModal: React.FC<ModalProps>,
): React.FC<ModalProps> => {
    const WithFullScreenSupport = React.forwardRef((props, ref) => {
        const { isOpen: isFullscreen, onToggle } = useDisclosure({
            defaultIsOpen: false,
        });

        useEffect(() => {
            fscreen.addEventListener('fullscreenchange', onToggle);
            return () => {
                fscreen.removeEventListener('fullscreenchange', onToggle);
            };
        }, [onToggle]);

        const target = useMemo(() => {
            if (!isFullscreen) {
                return null;
            } else {
                return fscreen.fullscreenElement as HTMLElement;
            }
        }, [isFullscreen]);

        const targetRef = useRef(target);
        targetRef.current = target;

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <WrappedModal
                ref={ref}
                portalProps={{
                    containerRef: target ? targetRef : undefined,
                }}
                {...props}
            />
        );
    });

    WithFullScreenSupport.displayName = `WithFullscreenSupport(${WrappedModal.displayName})`;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return WithFullScreenSupport;
};

export default withFullScreenSupport;
